<template>
  <div class="hello">
    <section class="projects-section bg-light" id="projects">
      <div class="container px-4 px-lg-5">
        <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
          <div class="col-xl-12">
            <div class="ffeatured-text text-center text-lg-left">
              <h4>Blog</h4>
              <p class="text-black-50 mb-0">{{ subtitle[lang] }}</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
          <!--div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="assets/img/bg-masthead.jpg" alt="..." /></div-->
          <div
            class="row gx-0 mb-5 mb-lg-0 justify-content-center"
            v-for="(p, indice) in posts"
            :key="indice"
          >
            <div class="col-lg-6" v-if="indice % 2 != 0">
              <img class="img-fluid" :src="p.imagen" alt="..." />
            </div>
            <div class="col-lg-6">
              <div
                class="bg-black text-center h-100 project"
                @click="verPost(p._id)"
              >
                <div class="d-flex h-100">
                  <div
                    class="project-text w-100 my-auto text-center text-lg-left"
                  >
                    <h4 class="text-white text-capitalize">
                      {{ p.titulo[lang] }}
                    </h4>
                    <p class="text-light">
                      {{ new Date().toISOString().substring(0, 10) }}
                    </p>
                    <p class="mb-0 text-white-50">
                      {{ p.contenido[lang].substring(0, 400) }}...
                    </p>
                    <hr class="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6" v-if="indice % 2 == 0">
              <img class="img-fluid" :src="p.imagen" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped></style>
<script>
import { LoremIpsum } from "lorem-ipsum";
const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});
//lorem.generateWords(1);
//lorem.generateSentences(5);
lorem.generateParagraphs(7);
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "blog",
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: Api.servidor,
      cdn: Api.cdn + "/",
      empresa: Api.obtenerLocal("bdlg.mx.empresa") || {},
      esMovil: Api.esMovil(),
      title: {
        es: "Publicaciones",
        en: "Blog",
      },
      subtitle: {
        es: "Nuestras publicaciones",
        en: "Our posts",
      },
      posts: [
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
      ],
    };
  },
  methods: {
    verPost(indice) {
      this.$router.push("/blog/" + indice);
    },
  },
  async mounted() {
    console.log("Blog", LoremIpsum);
    try {
      let posts = await axios.post(this.url + "/readbypass/post");
      const cdn = this.cdn;
      this.posts = posts.data
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((p) => {
          return {
            _id: p._id,
            titulo: {
              es: p.copy.es.nombre,
              en: p.copy.en.nombre,
            },
            contenido: {
              es: p.copy.es.contenido,
              en: p.copy.en.contenido,
            },
            imagen: cdn + p.imagen,
          };
        });
      console.log("posts?", posts.data, this.posts);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
